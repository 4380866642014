
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, PaperClipOutlined, StarOutlined, TagOutlined } from '@ant-design/icons-vue'

import ListCourse from '@/components/agency/course/ListCourse.vue'

import courseService from '../../../services/course'

interface CourseStatistc {
  active_course: number;
  pending_course: number;
  paid_course: number;
  free_course: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ListCourse,
    PaperClipOutlined,
    StarOutlined,
    TagOutlined
  },
  setup () {
    const courseType = ref<string>('Parent')
    const courseId = ref<string>('')
    const statistics = ref<CourseStatistc>()
    const getCourseStatistics = async () => {
      const responce: any = await courseService.getStatistics()
      statistics.value = responce.data
    }
    const refreshCourseStatistics = async () => {
      getCourseStatistics()
    }
    onMounted(() => {
      getCourseStatistics()
    })
    return {
      courseType,
      courseId,
      statistics,
      getCourseStatistics,
      refreshCourseStatistics
    }
  }
})
